<template>
<!-- 巡检任务 -->
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="管线巡检任务" name="first">
        <Dict v-if="activeName=='first'"></Dict>
      </el-tab-pane>
      <el-tab-pane label="设备巡检任务" name="fourth">
        <CollecIndex v-if="activeName=='fourth'"></CollecIndex>
      </el-tab-pane>
      <el-tab-pane label="事件巡检任务" name="second">
        <Cycle v-if="activeName=='second'"></Cycle>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Dict from './dict.vue'
import Cycle from './cycle.vue'
import CollecIndex from './collecIndex.vue'
export default {
  name: 'Index',
  components: { Dict , Cycle ,  CollecIndex  },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  mounted() {
    if(this.$route.query.first == 0) {
      this.activeName = 'first'
    }
    if(this.$route.query.first == 1) {
      this.activeName = 'fourth'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  // padding: 10px;
}
.tabs{
  margin: 10px;
  background: #ffffff;
  border-radius: 6px;
  padding:0 20px;
}
</style>
